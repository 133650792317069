import React, { FC } from 'react';
import { Icon } from '@design-system/icon';
import { Typography } from '@design-system/typography';
import { typographyMapper } from 'mappers/typography';

import Container from 'layout/Container';
import { ICONS } from 'utils/customIcons/icons';

import { IInfoBlockProps } from './models';

import './InfoBlock.scss';

const InfoBlock: FC<IInfoBlockProps> = ({ data }) => (
  <div data-testid="InfoBlock" className="info-block">
    <Container className="info-block__container">
      <div className="info-block__content">
        {data[0].groups.map(({ title, items }) => (
          <div key={title.content} className="info-block__group">
            <Typography {...typographyMapper(title)} className="info-block__group-title" />
            {items.map(({ icon, text }) => (
              <div key={text} className="info-block__item">
                {ICONS?.[icon.icon] ? (
                  <Icon icon={icon.icon} customIcons={[{ ...ICONS[icon.icon] }]} />
                ) : null}
                <Typography
                  variant="bodyS"
                  content={text}
                  wrapping="default"
                  tag="p"
                  className="info-block__item-text"
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </Container>
  </div>
);

export default InfoBlock;
