import React, { FC } from 'react';
import { PageHeader } from '@design-system/page-header-standard';
import { pageHeaderMapper } from 'mappers/pageHeader';

import { IBasePageHeaderProps } from './models';

import './BasePageHeader.scss';

const BasePageHeader: FC<IBasePageHeaderProps> = ({ data, fields }) => (
  <div data-testid="BasePageHeader" className="base-page-header">
    <PageHeader
      {...pageHeaderMapper({
        page_header: {
          ...data[0].page_header,
          image_element: [{ ...data[0].page_header.image_element[0] }],
          breadcrumbs: [{ hide_last_item: true, navigation_name: '', overflow_button: '' }],
        },
        fields,
      })}
    />
  </div>
);

export default BasePageHeader;
