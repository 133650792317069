import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout/Layout';
import BaseAccordion from 'components/BaseAccordion';
import BasePageHeader from 'components/BasePageHeader';
import DiscountOfferForm from 'components/DiscountOfferForm';
import ExternalArticlesList from 'components/ExternalArticlesList';
import ExternalProductsList from 'components/ExternalProductsList';
import InfoBlock from 'components/InfoBlock';
import { getBuildProcessInfo } from 'utils/buildProcess';
import withContentstackPreview from 'utils/livePreview';

import { TDiscountOfferPageData } from './models';

import './DiscountOfferPage.scss';

/**
 * Private & public template
 */
export const DiscountOfferPage: FC<TDiscountOfferPageData> = ({
  data: {
    pageData,
    footer,
    header,
    headerAccount,
    headerAdditional,
    headerNavigationLoggedIn,
    footerNavigationLoggedIn,
  },
  pageContext,
}) => {
  getBuildProcessInfo(pageContext);
  const {
    seo,
    fields,
    page_header,
    faq,
    info_block,
    external_products_list,
    external_articles_list,
    discount_offer_form,
  } = pageData;

  return (
    <Layout
      seo={seo}
      pageUid={pageContext.uid}
      color="blue"
      footer={footer}
      footerNavigationLoggedIn={footerNavigationLoggedIn}
      header={header}
      headerNavigationLoggedIn={headerNavigationLoggedIn}
      headerMode="inverse"
      headerAccount={headerAccount}
      headerAdditional={headerAdditional}
      privatePage={false}
      isHideHeaderNavigation
      data-testid="DiscountOfferPage"
      className="discount-offer-page"
    >
      {page_header?.[0]?.page_header ? <BasePageHeader data={page_header} fields={fields} /> : null}
      {discount_offer_form?.[0] ? <DiscountOfferForm data={discount_offer_form} /> : null}
      {info_block?.[0]?.groups?.length ? <InfoBlock data={info_block} /> : null}
      {external_products_list?.[0] ? <ExternalProductsList data={external_products_list} /> : null}
      {external_articles_list?.[0] ? <ExternalArticlesList data={external_articles_list} /> : null}
      {faq?.[0]?.items?.length ? <BaseAccordion data={faq} /> : null}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String, $locale: String) {
    pageData: contentstackFinishDiscountOfferTemplate(id: { eq: $id }) {
      uid
      seo {
        ...SeoFragment
      }
      fields {
        ...TemplateFieldsFragment
      }
      page_header {
        ...FinishPageHeaderFragment
      }
      faq {
        ...BaseAccordionFragment
      }
      info_block {
        ...InfoBlockFragment
      }
      external_products_list {
        ...FinishExternalProductsListFragment
      }
      external_articles_list {
        ...FinishExternalArticlesListFragment
      }
      discount_offer_form {
        ...FinishDiscountOfferFormFragment
      }
    }
    footer: contentstackFinishFooter(publish_details: { locale: { eq: $locale } }) {
      ...FooterFragment
    }
    header: contentstackFinishHeader(publish_details: { locale: { eq: $locale } }) {
      ...HeaderFragment
    }
    headerAdditional: contentstackFinishHeaderAdditional(
      publish_details: { locale: { eq: $locale } }
    ) {
      ...HeaderAdditionalFragment
    }
    headerAccount: contentstackFinishHeaderAccount(publish_details: { locale: { eq: $locale } }) {
      ...HeaderAccountFragment
    }
    headerNavigationLoggedIn: contentstackFinishNavigationLoggedIn(
      publish_details: { locale: { eq: $locale } }
    ) {
      ...HeaderNavigationLoggedInFragment
    }
    footerNavigationLoggedIn: contentstackFinishFooterNavigationLoggedIn(
      publish_details: { locale: { eq: $locale } }
    ) {
      ...FooterNavigationLoggedInFragment
    }
    redirects: contentstackFinishGuard(publish_details: { locale: { eq: $locale } }) {
      ...AuthGuardFragment
    }
  }
`;

export default withContentstackPreview(
  DiscountOfferPage,
  `${process.env.GATSBY_CONTENTSTACK_CONTENT_TYPES_PREFIX}_discount_offer_template`
);
