import React, { FC } from 'react';
import { FluidGrid } from '@design-system/fluid-grid';
import { PageBlock } from '@design-system/page-block';
import { ProductCard } from '@design-system/product-card';
import { linkMapper } from 'mappers/link';
import { pageBlockMapper } from 'mappers/pageBlock';

import { IExternalProductsListProps } from './models';

import './ExternalProductsList.scss';

const ExternalProductsList: FC<IExternalProductsListProps> = ({
  data: [{ page_block, items, card_cta, open_in_new_tab }],
}) => {
  const pageBlockData = pageBlockMapper(page_block);

  return (
    <div data-testid="ExternalProductsList" className="external-products-list">
      <PageBlock
        {...pageBlockData}
        heading={{
          ...pageBlockData.heading,
          align: 'center',
          headlineTag: 'h2',
          headlineVariant: 'headlineL',
        }}
      >
        <div>
          <FluidGrid tag="ul" variant="fixed-start" preferredCardSize={283}>
            {items.map(
              ({
                external_link,
                fields: {
                  productData: { productDescription, productTitle, heroImage },
                },
              }) => (
                <li key={productTitle}>
                  <ProductCard
                    variant="standard"
                    link={{
                      props: {
                        children: external_link.title,
                        href: external_link.href,
                        ...(open_in_new_tab
                          ? { rel: 'noreferrer noopener', target: '_blank' }
                          : {}),
                      },
                    }}
                    cardContent={{
                      headingBlock: {
                        headline: productTitle,
                        headlineTag: 'h3',
                        headlineVariant: 'headlineL',
                        description: productDescription,
                        descriptionVariant: 'bodyL',
                        align: 'center',
                      },
                    }}
                    cardMedia={{
                      image: {
                        props: {
                          src: heroImage.localAsset.childImageSharp.gatsbyImageData.images.fallback
                            ?.src as string,
                          alt: heroImage?.alt || productTitle,
                          objectFit: 'contain',
                          aspectRatio: '1 / 1',
                        },
                      },
                      mediaVariant: 'standard',
                    }}
                    cardCta={{
                      ctaBlock: {
                        align: 'center',
                        data: [
                          {
                            ...linkMapper({
                              ...card_cta,
                              link: [{ url: external_link.href, fields: null }],
                            }),
                            elementType: 'link',
                          },
                        ],
                      },
                    }}
                  />
                </li>
              )
            )}
          </FluidGrid>
        </div>
      </PageBlock>
    </div>
  );
};
export default ExternalProductsList;
