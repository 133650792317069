import React, { FC } from 'react';
import { linkMapper } from 'mappers/link';

import TMPLinkWrap from 'components/TMPLinkWrap';

import { IDiscountOfferFormProps } from './models';

import './DiscountOfferForm.scss';

const DiscountOfferForm: FC<IDiscountOfferFormProps> = ({
  data: [{ redirect_after_submit, external_link }],
}) => (
  <div data-testid="DiscountOfferForm" className="discount-offer-form">
    <TMPLinkWrap
      {...linkMapper({
        ...redirect_after_submit,
        ...(external_link
          ? {
              title: external_link.title,
              aria_label: external_link.title,
              link: [{ fields: null, url: external_link.href }],
            }
          : {}),
      })}
    />
  </div>
);
export default DiscountOfferForm;
