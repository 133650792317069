import React, { FC } from 'react';
import { Accordion } from '@design-system/accordion';
import { Typography } from '@design-system/typography';
import { typographyMapper } from 'mappers/typography';

import Container from 'layout/Container';
import { ICONS } from 'utils/customIcons/icons';

import { IBaseAccordionProps } from './models';

import './BaseAccordion.scss';

const BaseAccordion: FC<IBaseAccordionProps> = ({ data }) => (
  <div data-testid="BaseAccordion" className="base-accordion">
    <Container className="base-accordion__container">
      <div className="base-accordion__content">
        {data[0]?.header ? (
          <Typography
            {...typographyMapper(data[0].header)}
            variant="headlineXS"
            className="base-accordion__title"
          />
        ) : null}

        <Accordion
          expandVariant={data[0].expand_variant}
          expandedIndex={data[0].items.filter((item) => item.is_open).map((__, index) => index)}
          headlineTag={data[0].headline_tag}
          items={data[0].items.map((item) => ({
            children: <Typography content={item.text} variant="bodyM" />,
            title: item.title,
          }))}
          semanticVariant={data[0].semantic_variant}
          openIcon={{
            icon: 'plus',
            customIcons: [{ ...ICONS.plus }],
          }}
          closeIcon={{
            icon: 'minus',
            customIcons: [{ ...ICONS.minus }],
          }}
        />
      </div>
    </Container>
  </div>
);

export default BaseAccordion;
