import { PageBlockProps } from '@design-system/page-block';
import { headingBlockMapper } from 'mappers/headingBlock';

import { PageBlock, PageBlockUI } from './models';

export const pageBlockMapper = ({
  disableGutters,
  element,
  heading,
  mode,
  maxWidth,
}: PageBlock): PageBlockProps =>
  ({
    disableGutters,
    ...((heading?.headline || heading?.description) && { heading: headingBlockMapper(heading) }),
    ...(mode && { mode }),
    ...(element && { element }),
    ...(maxWidth && { maxWidth }),
  } as PageBlockProps);

export const pageBlockUIMapper = ({
  disableGutters,
  element,
  mode,
  maxWidth,
  heading_ui,
}: PageBlockUI): PageBlockProps =>
  ({
    disableGutters,
    heading: headingBlockMapper({
      ui: heading_ui,
      link: [],
      headline: null,
      description: null,
      description_rte: null,
    }),
    ...(mode && { mode }),
    ...(element && { element }),
    ...(maxWidth && { maxWidth }),
  } as PageBlockProps);
