import React, { FC } from 'react';
import { FluidGrid } from '@design-system/fluid-grid';
import { PageBlock } from '@design-system/page-block';
import { imageMapper } from 'mappers/image';
import { linkMapper } from 'mappers/link';
import { pageBlockMapper } from 'mappers/pageBlock';

import TMPLinkWrap from 'components/TMPLinkWrap';

import { IExternalArticlesListProps } from './models';

import './ExternalArticlesList.scss';

const ExternalArticlesList: FC<IExternalArticlesListProps> = ({
  data: [{ page_block, items, open_in_new_tab, link_settings }],
}) => {
  const pageBlockData = pageBlockMapper(page_block);

  return (
    <div data-testid="ExternalArticlesList" className="external-articles-list">
      <PageBlock
        {...pageBlockData}
        heading={{
          ...pageBlockData.heading,
          align: 'center',
          headlineTag: 'h2',
          headlineVariant: 'headlineL',
        }}
      >
        <div>
          <FluidGrid tag="ul" variant="fixed-start" preferredCardSize={264}>
            {items.map(({ external_link, header, image }) => (
              <li key={header.content} className="external-articles-list__item">
                <div className="external-articles-list__image">{imageMapper(image)?.element}</div>
                <TMPLinkWrap
                  {...linkMapper({
                    ...link_settings,
                    title: external_link.title,
                    aria_label: external_link.title,
                    link: [{ ...link_settings.link?.[0], url: external_link.href }],
                    ...(open_in_new_tab ? { rel: 'noreferrer noopener', target: '_blank' } : {}),
                  })}
                  className="external-articles-list__item-link"
                />
              </li>
            ))}
          </FluidGrid>
        </div>
      </PageBlock>
    </div>
  );
};
export default ExternalArticlesList;
